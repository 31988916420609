import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useIdle from '../../../shared/hooks/useIdle';
import useAuthentification from './useAuthentification';
import { logout } from './useLogout';
import useModal from '../../../shared/components/utilities/modal/useModal';

const useLogoutThenNIdleMinutes = (minutesToBeIdle = 30) => {
  const isIdle = useIdle(minutesToBeIdle);
  const modal = useModal();
  const { connected } = useAuthentification();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isIdle && connected) {
      modal.display({ title: 'Vous avez été déconnecté' });
      logout(dispatch);
    }
  }, [isIdle]);
};

export default useLogoutThenNIdleMinutes;
