import useLogoutThenNIdleMinutes from '../../domains/authentification/hooks/useLogoutThenNIdleMinutes';
import useUpdateUtilisateurStateOnConnectedChange from '../../domains/utilisateur/hooks/useUpdateUtilisateurStateOnConnectedChange';
import useShowConnexionModal from '../../domains/authentification/hooks/useShowConnexionModal';
import useGetRefreshTokenWhenTokenHasExpired from '../../domains/layout/hooks/useGetRefreshTokenWhenTokenHasExpired';
import useClearLocalStorage from './useClearLocalStorage';

const useLayoutLogic = () => {
  useLogoutThenNIdleMinutes(30);
  useUpdateUtilisateurStateOnConnectedChange();
  useGetRefreshTokenWhenTokenHasExpired();
  useClearLocalStorage();

  const showConnexionModal = useShowConnexionModal();

  return showConnexionModal();
};

export default useLayoutLogic;