/* eslint-disable no-unused-vars */
import { IGetUserInfoResponse } from '../authentification/authentification.model';
import {ICompte, ICompteSelectionne} from './utilisateur.model';

export enum UtilisateurAction {
  GET_USER_INFORMATION_REQUEST = 'utilisateur/GET_USER_INFORMATION_REQUEST',
  GET_USER_INFORMATION_SUCCESS = 'utilisateur/GET_USER_INFORMATION_SUCCESS',
  GET_USER_INFORMATION_FAILED = 'utilisateur/GET_USER_INFORMATION_FAILED',

  SET_UTILISATEUR_STATE_TO_UNDEFINED = 'utilisateur/SET_UTILISATEUR_STATE_TO_UNDEFINED',

  SET_ACCOUNTS = 'utilisateur/SET_ACCOUNTS',

  GET_ACCOUNTS_REQUEST = 'utilisateur/GET_ACCOUNTS_REQUEST',
  GET_ACCOUNTS_SUCCESS = 'utilisateur/GET_ACCOUNTS_SUCCESS',

  GET_SELECTED_ACCOUNT_REQUEST = 'utilisateur/GET_SELECTED_ACCOUNT_REQUEST',
  GET_SELECTED_ACCOUNT_ERROR = 'utilisateur/GET_SELECTED_ACCOUNT_ERROR',
  GET_SELECTED_ACCOUNT_SUCCESS = 'utilisateur/GET_SELECTED_ACCOUNT_SUCCESS',

  SET_ACCOUNT_SELECTED = 'utilisateur/SET_ACCOUNT_SELECTED',
}

export interface GetUserInformationRequestAction {
  type : UtilisateurAction.GET_USER_INFORMATION_REQUEST,
}

export interface GetUserInformationSuccessAction {
  type : UtilisateurAction.GET_USER_INFORMATION_SUCCESS,
  payload : IGetUserInfoResponse
}

export interface GetUserInformationFailedAction {
  type : UtilisateurAction.GET_USER_INFORMATION_FAILED,
  error : string
}

export interface SetAccountsdAction {
  type : UtilisateurAction.SET_ACCOUNTS,
  payload : ICompte[]
}

export interface SetUtilisateurStateToUndefinedAction {
  type : UtilisateurAction.SET_UTILISATEUR_STATE_TO_UNDEFINED,
}

export interface GetAccountsRequestAction {
  type : UtilisateurAction.GET_ACCOUNTS_REQUEST,
}

export interface GetAccountsSuccessAction {
  type : UtilisateurAction.GET_ACCOUNTS_SUCCESS,
  payload: ICompte[]
}

export interface GetSelectedAccountRequestAction {
  type : UtilisateurAction.GET_SELECTED_ACCOUNT_REQUEST,
}

export interface GetSelectedAccountSuccessAction {
  type : UtilisateurAction.GET_SELECTED_ACCOUNT_SUCCESS,
  payload: ICompteSelectionne
}

export interface SetAccountSelectedAction {
  type : UtilisateurAction.SET_ACCOUNT_SELECTED,
  payload: string
}

export interface GetSelectedAccountErrorAction {
  type: UtilisateurAction.GET_SELECTED_ACCOUNT_ERROR,
}

export const getUserInformationRequestAction = () : GetUserInformationRequestAction => ({
  type: UtilisateurAction.GET_USER_INFORMATION_REQUEST,
});

export const getUserInformationSuccessAction = (payload : IGetUserInfoResponse) : GetUserInformationSuccessAction => ({
  type: UtilisateurAction.GET_USER_INFORMATION_SUCCESS,
  payload,
});

export const getUserInformationFailedAction = (error : string) : GetUserInformationFailedAction => ({
  type: UtilisateurAction.GET_USER_INFORMATION_FAILED,
  error,
});

export const setAccountsAction = (payload : ICompte[]) : SetAccountsdAction => ({
  type: UtilisateurAction.SET_ACCOUNTS,
  payload,
});

export const setUtilisateurStateToUndefinedAction = () : SetUtilisateurStateToUndefinedAction => ({
  type: UtilisateurAction.SET_UTILISATEUR_STATE_TO_UNDEFINED,
});

export const getAccountsRequestAction = () : GetAccountsRequestAction => ({
  type: UtilisateurAction.GET_ACCOUNTS_REQUEST,
});

export const getAccountsSuccessAction = (payload : ICompte[]) : GetAccountsSuccessAction => ({
  type: UtilisateurAction.GET_ACCOUNTS_SUCCESS,
  payload,
});

export const getSelectedAccountRequestAction = () : GetSelectedAccountRequestAction => ({
  type: UtilisateurAction.GET_SELECTED_ACCOUNT_REQUEST,
});

export const getSelectedAccountSuccessAction = (payload : ICompteSelectionne) : GetSelectedAccountSuccessAction => ({
  type: UtilisateurAction.GET_SELECTED_ACCOUNT_SUCCESS,
  payload,
});

export const getSelectedAccountErrorAction = (): GetSelectedAccountErrorAction => ({
  type: UtilisateurAction.GET_SELECTED_ACCOUNT_ERROR,
});

export const setAccountSelectedAction = (payload?: string) : SetAccountSelectedAction => ({
  type: UtilisateurAction.SET_ACCOUNT_SELECTED,
  payload,
});

export type UtilisateurActions =
  GetUserInformationRequestAction |
  GetUserInformationSuccessAction |
  GetUserInformationFailedAction |

  SetUtilisateurStateToUndefinedAction |

  SetAccountsdAction |

  GetAccountsRequestAction |
  GetAccountsSuccessAction |
  GetSelectedAccountErrorAction |

  GetSelectedAccountRequestAction |
  GetSelectedAccountSuccessAction |

  SetAccountSelectedAction
