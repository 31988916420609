/* eslint-disable no-trailing-spaces,no-mixed-spaces-and-tabs,no-tabs,import/prefer-default-export */
import {
  ICompteSelectionne,
  ICompteSelectionneV2,
} from './utilisateur.model';
import { IGetUserInfoResponse, IGetUserInfoV2Response } from '../authentification/authentification.model';

export const compteSelectionneV2ToCompteSelectionne = (compteSelectionneV2: ICompteSelectionneV2): ICompteSelectionne => {
  const { compte_client, habilitations } = compteSelectionneV2;
  const {
    type_compte,
    type_profil,
    siren,
    id,
    version: num_version,
    grille_tarifaire,
    denomination_sociale,
    role_infogreffe,
    tva_intra_communautaire: tva_intracommunautaire,
  } = compte_client ?? {};
  const {
    libelle_facturation: libelle,
    contact_nom: nom,
    contact_prenom: prenom,
    ville_facturation: ville,
    pays_facturation: pays,
    complement_adresse_facturation: complement_adresse,
    numero_voie_facturation: adresse_correspondance,
    code_postal_facturation: code_postal,
  } = (compte_client?.abonnements?.[0] ?? {});

  return ({
    compteSelectionneV2,

    type_compte,
    type_profil,
    siren,
    id,
    num_version,
    grille_tarifaire,
    denomination_sociale,
    habilitations: habilitations?.split(',') ?? [],
    role_infogreffe,

    facturation_infos: {
      tva_intracommunautaire,
      libelle,
      nom,
      prenom,
      ville,
      pays,
      complement_adresse,
      adresse_correspondance,
      code_postal,
    },
  });
};

export const userInfoV2ToUserInfo = (userInfoV2: IGetUserInfoV2Response): IGetUserInfoResponse => {
  const {
    lastName: nom,
    firstName: prenom,
    email,
    attributes,
  } = userInfoV2;
  const {
    address,
    additionalAddress,
    zipCode,
    city,
    country,
    mobilePhone,
    phone,
  } = attributes ?? {};

  return ({
    userInfoV2,

    nom,
    prenom,
    email,

    contact_infos: {
      adresse_correspondance: address?.[0] ?? '',
      complement_adresse: additionalAddress?.[0] ?? '',
      code_postal: zipCode?.[0] ?? '',
      ville: city?.[0] ?? '',
      pays: country?.[0] ?? '',
      telephone_mobile: mobilePhone?.[0] ?? '',
      telephone_fixe: phone?.[0] ?? '',
    },
  });
};
