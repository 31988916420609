import qs from 'qs';

import { IConnectionPopUpEndPoint } from './authentification.model';

export const GET_CONNEXION_POPUP_ENDPOINT = ({ code_challenge }: IConnectionPopUpEndPoint): string => `${process.env.GATSBY_API_KEYCLOAK}/realms/${process.env.GATSBY_API_KEYCLOAK_REALM}/protocol/openid-connect/auth?${qs.stringify({
  client_id: KEYCLOAK_CLIENT_ID,
  response_type: 'code',
  code_challenge,
  code_challenge_method: 'S256',
  type: 'code',
  scope: 'openid',
  redirect_uri: window?.location?.origin,
})}`;

export const GET_DECONNEXION_ENDPOINT = (): string => `${process.env.GATSBY_API_KEYCLOAK}/realms/${process.env.GATSBY_API_KEYCLOAK_REALM}/protocol/openid-connect/logout?${qs.stringify({
  client_id: KEYCLOAK_CLIENT_ID,
  post_logout_redirect_uri: window?.location?.origin,
})}`;

export const OPENID_CONNECT_TOKEN_ENDPOINT = `${process.env.GATSBY_API_KEYCLOAK}/realms/${process.env.GATSBY_API_KEYCLOAK_REALM}/protocol/openid-connect/token`;
export const UTILISATEURS_INFOS_ENDPOINT = `${process.env.GATSBY_API_V2_USER}/utilisateurs`;
export const MOT_DE_PASSE_ENDPOINT = `${process.env.GATSBY_API_V2_USER}/utilisateurs/mot_de_passe`;

export const CONNEXION_ENDPOINT = '';

export const AUTH_CONNEXION_MJL_ENDPOINT = `${process.env.GATSBY_API_ACCOUNT}/auth/connexion-mjl`;

export const MOT_DE_PASSE_REINITIALISATION_ENDPOINT = `${process.env.GATSBY_API_ACCOUNT}/utilisateurs/mot_de_passe/reinitialisation`;
export const VERIFICATION_MODIFICATION_ADRESSE_EMAIL_ENDPOINT = `${process.env.GATSBY_API_ACCOUNT}/utilisateurs/adresse_electronique/verification`;
export const UTILISATEUR_CREATION_ENDPOINT = `${process.env.GATSBY_API_V2_USER}/utilisateurs`;
export const ACTIVATION_UTILISATEUR_ENDPOINT = `${process.env.GATSBY_API_ACCOUNT}/utilisateurs/activation`;
// TODO: supprimer ce endpoint lors du ticket du refacto de la mire de rinit de MDP.
export const MOT_DE_PASSE_REINITIALISATION_VERIFICATION_ENDPOINT = `${process.env.GATSBY_API_ACCOUNT}/utilisateurs/mot_de_passe/reinitialisation/verification`;
export const COMPTES_ENDPOINT = `${process.env.GATSBY_API_V2_ACCOUNT}/comptes_clients`;
export const SET_COMPTES = (compteId: string): string => `${process.env.GATSBY_API_V2_ACCOUNT}/comptes_clients/${compteId}/selectionner`;

export default CONNEXION_ENDPOINT;

export const KEYCLOAK_CLIENT_ID = 'frontend';

export interface IPanierState {
  isLoading: boolean
  isError: boolean,
}
