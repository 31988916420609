import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from '@reach/router';
import useAuthentification from '../../authentification/hooks/useAuthentification';
import { getRefreshTokenThunk } from '../../authentification/authentification.thunk';
import getFromLocalStorage from '../../../shared/utilities/localStorage/getFromLocalStorage';
import AUTHENTIFICATION_LOCAL_STORAGE_KEY from '../../authentification/authentification.local-storage';

const useGetRefreshTokenWhenTokenHasExpired = () => {
  const dispatch = useDispatch();
  const authentificationFromStore = useAuthentification();
  const { pathname } = useLocation();
  const { jwtToken, parsedAccessToken } = authentificationFromStore;
  const userFromLocalstorage = getFromLocalStorage(AUTHENTIFICATION_LOCAL_STORAGE_KEY, authentificationFromStore);

  useEffect(() => {
    if (parsedAccessToken && !!jwtToken?.refresh_token) {
      dispatch(getRefreshTokenThunk(jwtToken?.refresh_token));
    } else if (userFromLocalstorage?.parsedAccessToken && !!userFromLocalstorage?.jwtToken?.refresh_token) {
      dispatch(getRefreshTokenThunk(userFromLocalstorage?.jwtToken?.refresh_token));
    }
  }, [pathname]);
};

export default useGetRefreshTokenWhenTokenHasExpired;
