import React, { FC, ReactNode } from 'react';
import { Button, Modal } from '@impact/ui-portal';
import * as styles from './connexion-modal.module.scss';
import useLogin from '../../hooks/useLogin';

interface Props {
  setShowModal: (value: React.SetStateAction<boolean>) => void
  onConnectionSuccess?: () => void
  descriptionComponent?: ReactNode
  title?: string
}

const ConnexionModal: FC<Props> = ({
  setShowModal,
  onConnectionSuccess,
  descriptionComponent,
  title = 'Connexion',
}) => {
  const { login } = useLogin({ onSuccess: onConnectionSuccess });

  return (
    <Modal fitContent setShowModal={setShowModal} title={title}>
      <div className={styles.connexionWrapper}>
        {descriptionComponent || <div className={styles.serviceAccess}>
          Pour accéder à ce service,
          <span className={styles.serviceAccessBold}>veuillez vous connecter :</span>
        </div>}
        <Button
          className={styles.loginButton}
          color='blue_dark'
          backgroundColor='yellow'
          text='Se connecter'
          onClick={login}
        />
      </div>
    </Modal>
  );
};

export default ConnexionModal;
