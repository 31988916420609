// import { encode as base64encode } from 'base64-arraybuffer';
import { sha256 } from 'js-sha256';
import encodeBase64 from './encoreBase64';
import generateRandomString from '../../../shared/utilities/string/generate-random-string';

const generateCodeVerifierAndCodeChallenge = (): [string, string] => {
  const code_verifier = generateRandomString(128);
  const encoder = new TextEncoder();
  const data = encoder.encode(code_verifier);
  const base64Digest = encodeBase64(sha256.digest(data));
  const code_challenge = base64Digest
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');

  return [code_verifier, code_challenge];
};

export default generateCodeVerifierAndCodeChallenge;
