import { IGetUserInfoResponse } from '../authentification/authentification.model';
import {ICompte, ICompteSelectionne} from './utilisateur.model';

export interface IUtilisateurState {
  userInfo? : IGetUserInfoResponse,
  accounts?: ICompte[],
  selectedAccount?: ICompteSelectionne,
}

export const utilisateurInitialState : IUtilisateurState = {
};
