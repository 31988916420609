import { utilisateurInitialState, IUtilisateurState } from './utilisateur.state';
import { UtilisateurAction, UtilisateurActions } from './utilisateur.action';
import { ICompte } from './utilisateur.model';

const utilisateurReducer = (
  state : IUtilisateurState = utilisateurInitialState,
  action : UtilisateurActions,
) : IUtilisateurState => {
  switch (action.type) {
    case UtilisateurAction.GET_USER_INFORMATION_SUCCESS: return {
      ...state,
      userInfo: action.payload,
    };

    case UtilisateurAction.SET_UTILISATEUR_STATE_TO_UNDEFINED: return {
      ...state,
      userInfo: undefined,
      accounts: undefined,
      selectedAccount: undefined,
    };

    case UtilisateurAction.GET_ACCOUNTS_SUCCESS: return {
      ...state,
      accounts: action.payload,
    };

    case UtilisateurAction.GET_SELECTED_ACCOUNT_SUCCESS: return {
      ...state,
      selectedAccount: action.payload,
    };

    case UtilisateurAction.GET_SELECTED_ACCOUNT_ERROR: return {
      ...state,
      selectedAccount: {
        ...state?.selectedAccount,
        isLoading: false,
      },
    };

    case UtilisateurAction.SET_ACCOUNT_SELECTED: return {
      ...state,
      accounts: state?.accounts?.map((account: ICompte) => {
        if (action.payload === account.id) {
          // eslint-disable-next-line no-param-reassign
          account.selectionne = true;
        } else {
          // eslint-disable-next-line no-param-reassign
          account.selectionne = false;
        }
        return account;
      }),
    };

    default: return state;
  }
};

export default utilisateurReducer;
