import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAuthentification from '../../authentification/hooks/useAuthentification';
import getUtilisateurInformationThunk, { getAccountsThunk, getSelectedAccountThunk } from '../utilisateur.thunk';
import { setUtilisateurStateToUndefinedAction } from '../utilisateur.action';
import { selectUserAccounts } from '../utilisateur.selector';

const useUpdateUtilisateurStateOnConnectedChange = () => {
  const { connected } = useAuthentification();
  const accounts = useSelector(selectUserAccounts);
  const dispatch = useDispatch();
  const onLoadingLogin = sessionStorage.getItem('onLoadingLogin');

  useEffect(() => {
    if (connected) {
      if (!accounts && !onLoadingLogin) {
        dispatch(getAccountsThunk());
      }
      dispatch(getUtilisateurInformationThunk());
      dispatch(getSelectedAccountThunk());
    } else {
      dispatch(setUtilisateurStateToUndefinedAction());
    }
  }, [connected]);
};

export default useUpdateUtilisateurStateOnConnectedChange;
