/* eslint-disable import/prefer-default-export */
import { IGetUserInfoResponse } from '../authentification/authentification.model';
import authenticatedApiClient from '../authentification/authentification.api-client';
import {
  COMPTES_ENDPOINT,
  UTILISATEURS_INFOS_ENDPOINT,
} from '../authentification/authentification.constant';
import { ICompte, ICompteSelectionne } from './utilisateur.model';
import { COMPTES_SELECTIONNE_ENDPOINT } from './utilisateur.constants';
import {
  compteSelectionneV2ToCompteSelectionne,
  userInfoV2ToUserInfo,
} from './utilisateur.mapping';

export const getAccounts = async () : Promise<ICompte[]> => (await authenticatedApiClient.get(COMPTES_ENDPOINT)).data;

export const getSelectedAcount = async () : Promise<ICompteSelectionne> => compteSelectionneV2ToCompteSelectionne((await authenticatedApiClient.get(COMPTES_SELECTIONNE_ENDPOINT)).data);
export const getUtilisateurInformation = async () : Promise<IGetUserInfoResponse> => userInfoV2ToUserInfo((await authenticatedApiClient.get(UTILISATEURS_INFOS_ENDPOINT)).data);
