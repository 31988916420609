import React, {
  createContext, FC, useState, ReactNode,
} from 'react';
import { Modal } from '@impact/ui-portal';
import { IModalContextState, IModalParameters } from './modal.model';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const ModalContext = createContext<IModalContextState>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  display: () => { }, close: () => { },
});

const ModalContextProvider: FC = ({ children }) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [title, setTitle] = useState<string | undefined>();
  const [content, setContent] = useState<string | undefined | ReactNode>();

  const display = ({ title, content }: IModalParameters) => {
    setDisplayModal(true);
    setTitle(title);
    setContent(content);
  };

  const close = () => {
    setDisplayModal(false);
    setTitle(undefined);
    setContent(undefined);
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ModalContext.Provider value={{ display, close }}>
      {children}
      <div>
        {displayModal && <Modal setShowModal={() => setDisplayModal(!displayModal)} title={title}>{content}</Modal>}
      </div>
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
