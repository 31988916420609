import { useEffect } from 'react';
import useSharedLocalStorage from './useSharedLocalStorage';

const useClearLocalStorage = () => {
  const [localStorageVersion, setLocalStorageVersion] = useSharedLocalStorage('local_storage_version', '');
  const BUILD_VERSION = process.env.GATSBY_BUILD_BUILDID;
  useEffect(() => {
    if (BUILD_VERSION) {
      if (localStorageVersion !== '' && BUILD_VERSION !== localStorageVersion || localStorageVersion === '') {
        localStorage.clear();
        setLocalStorageVersion(BUILD_VERSION);
      }
    }
  }, []);
};

export default useClearLocalStorage;
