import React, { Suspense, useEffect } from "react";
import ToastContextProvider from "./src/shared/components/utilities/toast/toast.context";
import ModalContextProvider from "./src/shared/components/utilities/modal/modal.context";
import useLayoutLogic from "./src/shared/hooks/useLayoutLogic";
import { Provider } from "react-redux";
import store from "./src/domains/global-state/global-state.store";

export const wrapperRootElement = ({ element }) => {
    return <Provider store={store}>{element}</Provider>;
};

export const wrapperPageElement = ({ element, props }) => {
    useLayoutLogic();

    useEffect(() => {
        console.log('version:', process.env.GATSBY_BUILD_BUILDID); // Do not remove
    }, [])

    return (
        <Suspense fallback={null}>
            <ToastContextProvider>
                <ModalContextProvider>
                    {element}
                </ModalContextProvider>
            </ToastContextProvider>
        </Suspense>
    );
};
