import { useDispatch } from 'react-redux';
import { useState, Dispatch, useEffect } from 'react';
import { navigate } from 'gatsby';
import { GET_DECONNEXION_ENDPOINT } from '../authentification.constant';
import createPopup, { useListenPopupUrl } from '../../../shared/utilities/htmlElement/createPopup';
import { logoutSuccessAction } from '../authentification.action';

interface IUseLogoutResponse {
  logout: () => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logout = (dispatch: Dispatch<any>) => {
  dispatch(logoutSuccessAction());
  navigate('/');
};

const useLogout = (): IUseLogoutResponse => {
  const dispatch = useDispatch();
  const [popup, setPopup] = useState<Window | null>(null);
  const [isUrlChange, setUrlChange] = useState(false);
  const [isLogoutConfirmByKeycloak, setIsLogoutConfirmByKeycloak] = useState(false);

  const { setPopupFromListenPopupUrl } = useListenPopupUrl({
    popup,
    onPopupUrlChange: (popupUrl, intervalRef) => {
      setUrlChange(true);
      clearInterval(intervalRef.current);
    },
  });

  useEffect(() => {
    if (isUrlChange && isLogoutConfirmByKeycloak) {
      setTimeout(() => {
        logout(dispatch);
        popup?.close();
        setPopup(null);
        setIsLogoutConfirmByKeycloak(false);
        setUrlChange(false);
      }, 1000);
    }
  }, [isUrlChange, isLogoutConfirmByKeycloak]);
  useEffect(() => { setPopupFromListenPopupUrl(popup); }, [popup]);

  return {
    logout: () => {
      const connexion_popup_endpoint = GET_DECONNEXION_ENDPOINT();
      const popup = createPopup({ url: connexion_popup_endpoint, popup: {} });
      const { origin: domainOrigin } = (new URL(connexion_popup_endpoint));

      popup?.opener.addEventListener('message', ({ origin, data: message }: MessageEvent) => {
        if (origin === domainOrigin && message === 'LOGOUT_CANCEL') {
          popup?.close();
          setPopup(null);
        }
        if (origin === domainOrigin && message === 'LOGOUT_CONFIRM') {
          setIsLogoutConfirmByKeycloak(true);
        }
      });
      setPopup(popup);
    },
  };
};

export default useLogout;
