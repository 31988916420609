import { combineReducers } from 'redux';
import authentificationReducer from '../authentification/authentification.reducer';
import utilisateurReducer from '../utilisateur/utilisateur.reducer';

const rootReducer = combineReducers(
  {
    authentification: authentificationReducer,
    utilisateur: utilisateurReducer,
  },
);

export default rootReducer;
