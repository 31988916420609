import { useState } from 'react';
import getFromLocalStorage from '../utilities/localStorage/getFromLocalStorage';
import setFromLocalStorage from '../utilities/localStorage/setFromLocalStorage';
import removeFromLocalStorage from '../utilities/localStorage/removeFromLocalStorage';

type UseLocalStorageResponse<T> = [() => T, (value: T) => void, () => void]

const useLocalStorage = <T, >(key : string, initialValue : T) : UseLocalStorageResponse<T> => {
  const get = () :T => getFromLocalStorage(key, initialValue);
  const set = (value : T) => { setValue(value); setFromLocalStorage(key, value); };
  const remove = () : void => removeFromLocalStorage(key);

  // permet uniquement de forcer des rerendus quand l'état se met à jour.
  const [, setValue] = useState(get());

  return [get, set, remove];
};

export default useLocalStorage;
