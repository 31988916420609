// From Gatsby
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import qs from 'qs';

export const URL_HOME = '/';

// portefeuille
export const URL_BACKOFFICE_GREFFIER = '/backoffice-greffier';
export const URL_PORTEFEUILLE_ELECTRONIQUE = '/portefeuille-electronique-details';

// commande
const URL_COMMANDES = '/commandes';

// kbis-periodique
export const URL_KBIS_PERIODIQUE = `${URL_COMMANDES}/kbis-periodique`;

// fiche de vie
export const URL_FICHE_DE_VIE = `/supervision`;
export const URL_FICHE_DE_VIE_BY_PROCESS = (processus) => `${URL_FICHE_DE_VIE}/${processus}`;

// authentification
export const URL_INSCRIPTION = '/inscription';
export const URL_INSCRIPTION_VALIDATION = '/inscription-validation';
export const URL_CREER_UN_NOUVEAU_MOT_DE_PASSE = (code) => `/creer-nouveau-mot-de-passe?${qs.stringify({ code })}`;
export const URL_REINITIALISATION_DU_MOT_DE_PASSE = '/reinitialisation-du-mot-de-passe';
export const URL_REINITIALISATION_DU_MOT_DE_PASSE_EN_COURS = (email) => `/reinitialisation-du-mot-de-passe-en-cours?${qs.stringify({ email })}`;
export const URL_REINITIALISATION_DU_MOT_DE_PASSE_EXPIREE = '/reinitialisation-du-mot-de-passe-expiree';
export const URL_MODIFICATION_EMAIL_TERMINEE = '/modification-email-terminee';
export const URL_MODIFICATION_EMAIL_EXPIREE = '/modification-email-expiree';
