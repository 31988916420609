// extracted by mini-css-extract-plugin
export var black = "#262c2f";
export var blue_dark = "#002c75";
export var blue_gray = "#496776";
export var blue_light = "#e5ecef";
export var blue_sky = "#4796d8";
export var bold = "useShowConnexionModal-module--bold--5d8e4";
export var description = "useShowConnexionModal-module--description--ef482";
export var fontSize1 = "45px";
export var fontSize2 = "24px";
export var fontSize3 = "16px";
export var fontSize4 = "14px";
export var fontSize5 = "12px";
export var fontSize6 = "10px";
export var gray_blue = "#27374e";
export var gray_dark = "#3c3c3c";
export var gray_light = "#f6f7f8";
export var gray_medium = "#a7a7a7";
export var green_blue = "#36a4a3";
export var green_dark = "#3f986f";
export var green_light = "#8ad489";
export var red = "#d41717";
export var rotating = "useShowConnexionModal-module--rotating--0f379";
export var white = "#fff";
export var white_off = "#eef0f1";
export var yellow = "#fec844";