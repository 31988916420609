/* eslint-disable no-underscore-dangle, no-param-reassign, @typescript-eslint/ban-ts-comment */
import axios, { AxiosRequestConfig } from 'axios';
import store from '../global-state/global-state.store';
import { getRefreshTokenFailedAction } from './authentification.action';
import CONNEXION_ENDPOINT from './authentification.constant';
import { getAuthentificationFromLocalStorage } from './authentification.local-storage';
import { IJwtToken } from './authentification.state';
import { getRefreshTokenThunk } from './authentification.thunk';

const selectJwtToken = (): IJwtToken | undefined => (store.getState()?.authentification?.parsedAccessToken
  ? store.getState()?.authentification?.jwtToken : getAuthentificationFromLocalStorage().jwtToken);
const selectAccessToken = (): string | undefined => selectJwtToken()?.access_token;
const selectRefreshToken = (): string | undefined => selectJwtToken()?.refresh_token;

const authenticatedApiClient = axios.create();

authenticatedApiClient.interceptors.request.use((requestConfiguration: AxiosRequestConfig) => {
  const accessToken = selectAccessToken();
  if (accessToken) {
    // @ts-ignore
    requestConfiguration.headers.Authorization = `Bearer ${accessToken}`;
  }
  return requestConfiguration;
},
(error) => Promise.reject(error));

authenticatedApiClient.interceptors.response.use((axiosResponse) => axiosResponse,
  async (previousError) => {
    const previousApiClientConfiguration = previousError.config; // ne pas mettre baseURL, ça fera crash.
    const shouldGetRefreshToken = !previousApiClientConfiguration.url.includes(CONNEXION_ENDPOINT)
      && previousError.response?.status === 401
      && !previousApiClientConfiguration._retry;

    if (shouldGetRefreshToken) {
      previousApiClientConfiguration._retry = true;

      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        store.dispatch(getRefreshTokenThunk(selectRefreshToken()!));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        store.dispatch(getRefreshTokenFailedAction(error.message));

        return Promise.reject(error);
      }
    }

    return Promise.reject(previousError);
  });

export default authenticatedApiClient;
