import ThunkDispatchType from '../global-state/global-state.model';
import {
  getUserInformationFailedAction,
  getUserInformationRequestAction,
  getUserInformationSuccessAction,
  getAccountsRequestAction,
  getAccountsSuccessAction, getSelectedAccountRequestAction, getSelectedAccountSuccessAction, getSelectedAccountErrorAction,
} from './utilisateur.action';
import {getUtilisateurInformation, getAccounts, getSelectedAcount} from './utilisateur.api';

const getUtilisateurInformationThunk = () => async (dispatch : ThunkDispatchType) => {
  try {
    dispatch(getUserInformationRequestAction());

    const userInformation = await getUtilisateurInformation();

    dispatch(getUserInformationSuccessAction(userInformation));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    dispatch(getUserInformationFailedAction(error.message));
  }
};

export const getAccountsThunk = () => async (dispatch : ThunkDispatchType) => {
  try {
    dispatch(getAccountsRequestAction());
    const userAccounts = await getAccounts();
    dispatch(getAccountsSuccessAction(userAccounts));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
  } catch (error: any) {}
};


export const getSelectedAccountThunk = () => async (dispatch : ThunkDispatchType) => {
  try {
    dispatch(getSelectedAccountRequestAction());
    const seletedAccount = await getSelectedAcount();
    dispatch(getSelectedAccountSuccessAction(seletedAccount));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
  } catch (error: any) {
    dispatch(getSelectedAccountErrorAction());
  }
};

export default getUtilisateurInformationThunk;
