import {wrapperPageElement, wrapperRootElement} from './gatsby-app-wrapper';
import type { GatsbyBrowser } from "gatsby"

export const onRouteUpdate = () => {
  if (process.env.GATSBY_PORTAL_ENV !== '.env.production') {
    return null;
  }
};

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = wrapperRootElement;
export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = wrapperPageElement;