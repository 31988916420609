import IAuthentificationState, { authentificationInitialState } from '../authentification/authentification.state';
import { utilisateurInitialState, IUtilisateurState } from '../utilisateur/utilisateur.state';

export interface IApplicationState {
  authentification: IAuthentificationState
  utilisateur: IUtilisateurState
}

export const initialApplicationState: IApplicationState = {
  authentification: authentificationInitialState,
  utilisateur: utilisateurInitialState,
};
