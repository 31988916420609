import { MutableRefObject } from 'react';
import {
  loginFailedAction,
  loginRequestAction,
  loginSuccessAction,
  logoutSuccessAction,
  getRefreshTokenFailedAction,
  getRefreshTokenRequestAction,
  getRefreshTokenSuccessAction,
} from './authentification.action';
import ThunkDispatchType from '../global-state/global-state.model';
import { getRefreshToken, getKeycloakToken } from './authentification.api';

// eslint-disable-next-line import/prefer-default-export
export const logoutThunk = () => (dispatch: ThunkDispatchType) => {
  try {
    dispatch(logoutSuccessAction());
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,no-empty
  } catch (error: any) { }
};

// PAYLOAD
interface ILoginKeycloakThunk {
  code?: string,
  code_verifier: string,
  redirect_uri?: string,
  intervalRef: MutableRefObject<number | undefined>,
  popup: Window | null,
  setPopup: (popup: Window | null) => void,
  onSuccessOrError?: () => void
  onSuccess?: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError?: (httpStatus: number) => any
}

// login with a PKCE/Authorization code flow :
// 1) open 'openid-connect/auth' in a popup
// 2) Read the popup code query parameter
// 3) POST this code to 'openid-connect/token' to retrieve a new JWT Token.
export const loginKeycloakThunk = (
  {
    code,
    code_verifier,
    redirect_uri,
    intervalRef,
    popup,
    setPopup,
    onSuccess,
    onError,
    onSuccessOrError,
  }: ILoginKeycloakThunk) => (dispatch: ThunkDispatchType) => {
  dispatch(loginRequestAction());

  if (code) {
    sessionStorage.setItem('onLoadingLogin', 'true');
    clearInterval(intervalRef.current);
    getKeycloakToken({ code, redirect_uri, code_verifier })
      .then((jwtToken) => {
        dispatch(loginSuccessAction(jwtToken.data));
        onSuccess?.();
      })
      .catch((error) => {
        loginFailedAction(error.message);
        onError?.(error.response);
      })
      .finally(() => {
        popup?.close();
        setPopup(null);
        onSuccessOrError?.();
      });
  }
};

export const getRefreshTokenThunk = (refresh_token: string) => (dispatch: ThunkDispatchType) => {
  dispatch(getRefreshTokenRequestAction());
  getRefreshToken({ refresh_token })
    .then((data) => dispatch(getRefreshTokenSuccessAction(data?.data)))
    .catch((error) => dispatch(getRefreshTokenFailedAction(error.message)));
};
