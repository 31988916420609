import axios, { AxiosResponse } from 'axios';
import { FormikProps } from 'formik';
import { Dispatch } from 'react';
import HttpStatus from '../../shared/constants/httpStatus';
import {
  KEYCLOAK_CLIENT_ID,
  OPENID_CONNECT_TOKEN_ENDPOINT,
  UTILISATEUR_CREATION_ENDPOINT,
} from './authentification.constant';
import IRegistrationFormProps, { IGetRefreshTokenPayload } from './authentification.model';
import { IJwtToken } from './authentification.state';
import utf8ToBase64 from '../../shared/utilities/string/utf8ToBase64';
import XWwwFormUrlencoded from '../../shared/utilities/http/xWwwFormUrlencoded';

interface Props {
  formik: FormikProps<IRegistrationFormProps>
  setErrorApi: Dispatch<string>
}

const createAccount = ({ formik, setErrorApi }: Props, onCreatedAccount: () => void) => {
  const { values } = formik;
  setErrorApi('');
  axios.post(UTILISATEUR_CREATION_ENDPOINT, {
    prenom: values.prenom,
    nom: values.nom,
    adresse_electronique: values.adresseElectronique,
    mot_de_passe: values.motDePasse,
    rgpd_consenti: values.rgpdConsenti,
    conditions_generales_acceptees: values.conditionsGeneralesAcceptees,
    newsletter_souscrie: values.newsletterSouscrie,
  })
    .then((response) => {
      console.log(response);

      if (response.status === HttpStatus.CREATED) onCreatedAccount();
    })
    .catch((error) => {
      if (error.response.data.code === 'EMAIL_IN_USE') {
        formik.setFieldError('adresseElectronique', 'L\'adresse e-mail saisie est déjà utilisée.');
      } else {
        setErrorApi(error.message);
      }
    });
};

export interface IGetKeycloakToken {
  code?: string,
  code_verifier: string
  redirect_uri?: string,
}

export const getKeycloakToken = ({ code_verifier, code, redirect_uri }: IGetKeycloakToken): Promise<AxiosResponse<IJwtToken>> => axios.post<IJwtToken>(OPENID_CONNECT_TOKEN_ENDPOINT, XWwwFormUrlencoded({
  code,
  code_verifier,
  redirect_uri: `${window?.location?.origin}${redirect_uri ? `/${redirect_uri}` : ''}`,
  client_id: KEYCLOAK_CLIENT_ID,
  grant_type: 'authorization_code',
  scope: 'openid',
}), {
  headers: {
    Authorization: `Basic ${utf8ToBase64(`${KEYCLOAK_CLIENT_ID}:`)}`,
  },
});

export const getRefreshToken = async ({ refresh_token }: IGetRefreshTokenPayload):
  Promise<IJwtToken> => (axios.post(OPENID_CONNECT_TOKEN_ENDPOINT, XWwwFormUrlencoded({
  client_id: KEYCLOAK_CLIENT_ID,
  refresh_token,
  grant_type: 'refresh_token',
}),
));

export default createAccount;
