import React, {ReactElement} from "react";
import {useDispatch} from "react-redux";
import useAuthentification from "./useAuthentification";
import ConnexionModal from "../components/connexion-modal/connexion-modal";
import {hideConnexionModalAction} from "../authentification.action";
import * as styles from './useShowConnexionModal.module.scss';

const useShowConnexionModal = () => {
  const { connected, showConnexionModal } = useAuthentification();
  const dispatch = useDispatch();

  const showConnexionModalFunction = () : ReactElement => !connected && showConnexionModal ?
    <ConnexionModal
      setShowModal={() => dispatch(hideConnexionModalAction())}
      onClickOnReinitialisationDuMotDePasseOuInscription={() => dispatch(hideConnexionModalAction())}
      descriptionComponent={
        <div className={styles.description}>
          {'Pour changer de compte client, '}
          <span className={styles.bold}>veuillez-vous reconnecter.</span>
        </div>}
    /> : <div/>;

  return showConnexionModalFunction;
};

export default useShowConnexionModal;